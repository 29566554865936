import { gql, useMutation, useQuery } from '@apollo/client'
import { StarRounded } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import { isUndefined } from 'lodash'
import React, { useCallback } from 'react'

import {
  QUESTION_COUNTS_QUERY,
  QuestionCountsQuery,
} from '../../../../graphql/hooks/question/useQuestionCounts/useQuestionCounts'
import { useCurrentUser } from '../../../../graphql/hooks/users/useCurrentUser/useCurrentUser'

interface FavoritingStarProps {
  questionId: number
  favorited?: boolean
  onToggle?: (wasFavorited: boolean) => void
}

const FAVORITE_QUESTION_MUTATION = gql`
  mutation FavoriteQuestion($id: Int!, $remove: Boolean!) {
    favoriteQuestion(input: { id: $id, remove: $remove }) {
      question {
        id
        favorited
        user {
          id
        }
      }
    }
  }
`

const FAVORITE_QUESTION_QUERY = gql`
  query FavoritingStar($id: Int!) {
    question(id: $id) {
      id
      favorited
    }
  }
`

const useStyles = makeStyles((theme) => ({
  hollowStar: {
    fill: theme.palette.background.default,
    stroke: theme.palette.text.secondary,
  },
  yellowStar: {
    fill: theme.palette.question?.favorite,
    stroke: theme.palette.text.primary,
  },
}))

export const FavoritingStar: React.FC<FavoritingStarProps> = ({
  questionId,
  favorited,
  onToggle,
}) => {
  const { currentUser } = useCurrentUser()
  const noFavProp = isUndefined(favorited)
  const [favoriteQuestion] = useMutation(FAVORITE_QUESTION_MUTATION, {
    update(cache, { data }) {
      const toggeledQuestion = data?.favoriteQuestion.question

      const currentUserQuestion = toggeledQuestion.user?.id === currentUser?.id ? true : false

      const countsQuery = cache.readQuery<QuestionCountsQuery>({
        query: QUESTION_COUNTS_QUERY,
      })

      if (countsQuery && toggeledQuestion && !currentUserQuestion) {
        let newTotalRecords = countsQuery.user.totalRecords
        if (toggeledQuestion.favorited) {
          newTotalRecords += 1
        } else {
          newTotalRecords -= 1
        }

        cache.writeQuery({
          query: QUESTION_COUNTS_QUERY,
          data: {
            user: {
              ...countsQuery.user,
              totalRecords: newTotalRecords,
            },
          },
        })
      }
    },
  })

  const { data, loading } = useQuery(FAVORITE_QUESTION_QUERY, {
    variables: { id: questionId },
    skip: !noFavProp,
  })

  let fav = favorited
  if (noFavProp) {
    fav = data?.question.favorited
  }

  const toggleFavorite = useCallback(() => {
    favoriteQuestion({
      variables: {
        id: questionId,
        remove: !!fav,
      },
    })
    onToggle?.(!fav)
  }, [questionId, fav, onToggle])

  const styles = useStyles()

  if (loading) {
    return null
  }

  return (
    <IconButton onClick={toggleFavorite} size="small">
      <StarRounded className={fav ? styles.yellowStar : styles.hollowStar} />
    </IconButton>
  )
}
